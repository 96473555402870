import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import Loader from "../../loader/Loader";

const TermsAndConditions = () => {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="hero">
            <Container>
              <Row className="justify-content-center">
                <Col xl="17">
                  <h1 className="text-center mb-0">Terms coming soon</h1>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row className="justify-content-center mb-11 mb-md-12 mb-xxl-13 mb-xxxl-14">
              <Col xl="18" xxl="16" xxxl="13"></Col>
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default TermsAndConditions;
