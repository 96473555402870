import React from "react";
import { Row, Col, Button, Image, Card} from "react-bootstrap";
import familyAroundTablet from '../../assets/images/index/family-around-tablet.jpg';
import familyAroundTabletMD from '../../assets/images/index/family-around-tablet-md.jpg';
import familyAroundTabletXL from '../../assets/images/index/family-around-tablet-xl.jpg';
import logo from '../../assets/images/logo.svg';

const UspSection = () => {

  return (
    <section className="pb-11 pb-md-12 pb-xl-13 pb-xxxl-14">
      <div className="bg-primary-light text-center rounded-3 pb-12 pt-9 px-7 pt-md-10 pb-md-13 pt-xxxl-11 pb-xxxl-14">
        <Image src={logo} height="53" className="d-md-none mb-7" alt="" />
        <Image src={logo} height="64" className="d-none d-md-inline-block d-xl-none mb-md-8" alt="" />
        <Image src={logo} height="80" className="d-none d-xl-inline-block mb-xl-9" alt="" />
        <h2 className="text-center mb-0 mb-xl-11">Built on four main principles</h2>
      </div>
      <div className="px-7 px-xxl-9 px-xxxl-11 mb-7 mb-md-8 mb-xl-9 mb-xxxl-10 mt-n11 mt-md-n12 mt-xxl-n13 mb-xxxl-10 mt-xxxl-n14">
        <Row className="gy-5 gx-md-6 gy-md-6 gx-xl-4 gy-xl-9 gx-xxl-5 gx-xxxl-6">
          <Col className="col-24 col-md-12 col-xl mt-xxl-9">
            <Card className="border-primary">
              <Card.Body className="p-5 p-md-6">
                <i className="fa fa-regular fa-eye lead-xl text-primary-dark mb-4"></i>
                <h6 className="lead mb-3">Giving you full control</h6>
                <p className="small-xs mb-0">Full visibility over all your finances any time, anywhere.</p>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-24 col-md-12 col-xl mt-xxl-9">
            <Card className="border-primary">
              <Card.Body className="p-5 p-md-6">
                <i className="fa fa-regular fa-bell lead-xl text-primary-dark mb-4"></i>
                <h6 className="lead mb-3">Keeping you reminded</h6>
                <p className="small-xs mb-0">Set reminders for everything from bills to birthday parties.</p>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-24 col-xl-7 col-xxl-6 gy-7 gy-md-6 gy-xl-0 gx-xl-6 gx-xxl-9 gx-xxxl-10">
            <div className="w-100 h-100 rounded-3 overflow-hidden">
              <Image src={familyAroundTablet} className="img-object-fit-cover d-md-none" alt="Son cooking with elderly father" />
              <Image src={familyAroundTabletMD} className="img-object-fit-cover d-none d-md-block d-xl-none" alt="Son cooking with elderly father" />
              <Image src={familyAroundTabletXL} className="img-object-fit-cover d-none d-xl-block" alt="Son cooking with elderly father" />
            </div>
          </Col>
          <Col className="col-24 col-md-12 col-xl gy-7 gy-md-6 gy-xl-8 mt-xxl-9">
            <Card className="border-primary">
              <Card.Body className="p-5 p-md-6">
                <i className="fa fa-regular fa-money-bill-1 lead-xl text-primary-dark mb-4"></i>
                <h6 className="lead mb-3">Helping you save money</h6>
                <p className="small-xs mb-0">Save up to £701 on with our exclusive Compare & Save tool.</p>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-24 col-md-12 col-xl mt-xxl-9">
            <Card className="border-primary">
              <Card.Body className="p-5 p-md-6">
                <i className="fa fa-regular fa-face-smile lead-xl text-primary-dark mb-4"></i>
                <h6 className="lead mb-3">Supported by your circle</h6>
                <p className="small-xs mb-0">Get a helping hand from your family and loved ones with our Support users.</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="text-center">
        <Button variant="outline-info" href="/our-Mission" size="lg">Our mission, straight from the heart</Button>
      </div>
    </section>
  )
};

export default UspSection;