import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
// import Swiper JS
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./TestimonialsSlider.scss";

import SarahThompson from "../../assets/images/index/Sarah_Thompson.jpg"; // https://www.shutterstock.com/image-photo/portrait-happy-mother-hugging-two-cute-2035177835
import MargaretEvans from "../../assets/images/index/Maragret_Evans.jpg"; // https://www.shutterstock.com/image-photo/cheerful-pretty-blonde-older-woman-elegant-2365473977
import JohnMayerCarter from "../../assets/images/index/John_and_Mary_Carter.jpg"; // https://www.shutterstock.com/image-photo/happy-mature-husband-wife-sit-rest-2473263319
import JamesHolden from "../../assets/images/index/James_Holden.jpg"; // https://www.shutterstock.com/image-photo/successful-caucasian-young-man-student-freelancer-2141124049
import RebecaHill from "../../assets/images/index/Rebeca_Hill.jpg"; // https://www.shutterstock.com/image-photo/young-woman-park-wearing-winter-clothing-2040685925
import RayShah from "../../assets/images/index/ray-shah.jpg";

const TestimonialsSlider = () => {
  return (
    <section className="testimonials-slider pb-11 pb-md-12 pb-xl-13 pb-xxxl-14">
      <Container className="text-center mb-10 mb-md-11 mb-xl-12">
        <i className="fa fa-solid fa-message fs-2 text-emphasize mb-3"></i>
        <h2 className="text-emphasize mb-0">Loved is an understatement</h2>
      </Container>
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={32}
        slidesPerView={1.3}
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 4000,
        }}
        sli
        wrapperClass="mb-9"
      >
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image
                        src={SarahThompson}
                        className="img-object-fit-cover"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p
                      className="fs-6 text-dark mb-6 mb-md-7"
                      style={{ minHeight: "16rem" }}
                    >
                      Juggling bills, to-dos, and warranties for the entire
                      family was a nightmare. Elderly Life brought us together,
                      making it simple to stay organised and save money. Now, we
                      can focus on enjoying more quality time as a family."
                    </p>
                    <p className="lead text-dark fw-semibold mb-1">
                      Sarah Thompson
                    </p>
                    <p className="small text-dark mb-0">Mother of two</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image
                        src={MargaretEvans}
                        className="img-object-fit-cover"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p
                      className="fs-6 text-dark mb-6 mb-md-7"
                      style={{ minHeight: "16rem" }}
                    >
                      I used to struggle keeping track of my gift cards,
                      warranties, and bills. My son introduced me to Elderly
                      Life, and now he helps me manage everything with ease. I
                      feel so supported and stress-free!”
                    </p>
                    <p className="lead text-dark fw-semibold mb-1">
                      Margaret Evans
                    </p>
                    <p className="small text-dark mb-0">Retired Teacher</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image
                        src={JohnMayerCarter}
                        className="img-object-fit-cover"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p
                      className="fs-6 text-dark mb-6 mb-md-7"
                      style={{ minHeight: "16rem" }}
                    >
                      We always forgot about expiring warranties or missing
                      vouchers, costing us money. Thanks to Elderly Life, with
                      the help of our kids, everything is in one place, and we
                      never miss a beat!
                    </p>
                    <p className="lead text-dark fw-semibold mb-1">
                      John and Mary Carter
                    </p>
                    <p className="small text-dark mb-0">Proud Grandparents</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image
                        src={JamesHolden}
                        className="img-object-fit-cover"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p
                      className="fs-6 text-dark mb-6 mb-md-7"
                      style={{ minHeight: "16rem" }}
                    >
                      Living alone means keeping track of everything myself.
                      Elderly Life has made it so much easier to stay organised
                      and save money. It’s like having a personal assistant in
                      my pocket!"*
                    </p>
                    <p className="lead text-dark fw-semibold mb-1">
                      James Holden
                    </p>
                    <p className="small text-dark mb-0">Freelancer</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image
                        src={RebecaHill}
                        className="img-object-fit-cover"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p
                      className="fs-6 text-dark mb-6 mb-md-7"
                      style={{ minHeight: "16rem" }}
                    >
                      Managing my parents' bills and appointments while handling
                      my own was overwhelming. Elderly Life simplified
                      everything. Now, I can support them without the stress,
                      and they feel more connected too!
                    </p>
                    <p className="lead text-dark fw-semibold mb-1">
                      Rebecca Hill
                    </p>
                    <p className="small text-dark mb-0">
                      Daughter and Caregiver
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image
                        src={RayShah}
                        className="img-object-fit-cover"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p style={{ minHeight: "16rem" }}>
                      <p className="fs-6 text-dark mb-6 mb-md-7">
                        I was stuck in a cycle of contract renewal out of
                        convenience.
                      </p>
                      <p className="fs-6 text-dark mb-5 mb-md-6">
                        Elderly Life helped me save money and time, meaning I
                        can focus on the important things more – like my golf
                        handicap.
                      </p>
                    </p>
                    <p className="lead text-dark fw-semibold mb-1">Ray Shah</p>
                    <p className="small text-dark mb-0">Father of three</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default TestimonialsSlider;
