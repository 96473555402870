import "./application.scss";
import { Routes, Route } from "react-router-dom";
import Home from "../src/components/pages/home/Home";
import PrivacyPolicy from "../src/components/pages/privacyPolicy/PrivacyPolicy";
import Comingsoon from "../src/components/comingsoon/Comingsoon";
import PageNotFound from "./components/404page/PageNotFound";
import OurMission from "./components/pages/ourMission/OurMission";
import HowItWorks from "./components/pages/howItWorks/HowItWorks";
import Plan from "./components/pages/plan/Plan";
import CookiePolicy from "./components/pages/cookiePolicy/CookiePolicy";
import TermsAndConditions from "./components/pages/termsAndConditions/TermsAndConditions";

function App() {
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Routes>
        <Route path="/" element={<Comingsoon />} />
        <Route path="/home" element={<Home />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/our-mission" element={<OurMission />} />
        <Route path="/plans" element={<Plan />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/#" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/comingsoon" element={<Comingsoon />} />
      </Routes>
    </>
  );
}

export default App;
