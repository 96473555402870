import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import HeroGraphicOutgoings from "../../../assets/images/outgoings/outgoings-screenshot.jpg";
import HeroGraphicReminders from "../../../assets/images/outgoings/reminders-screenshot.jpg";
import HeroGraphicBenefits from "../../../assets/images/outgoings/benefits-screenshot.jpg";
import SupportUserSection from "../../templates/SupportUserSection";
import UspSection from "../../templates/UspSection";
import PricingPlanCallToAction from "../../templates/PricingPlanCallToAction";
import PageCallToAction from "../PageCallToAction";
import sonCookingWithElderlyFather from "../../../assets/images/index/son-cooking-with-elderly-father.jpg";
import grandadGradeningWithGranddaughter from "../../../assets/images/index/grandad-gradening-with-granddaughter.jpg";
import screenie1 from "../../../assets/images/index/screenshots/screenie-1.jpg";
import screenie2 from "../../../assets/images/index/screenshots/screenie-2.jpg";
import screenie3 from "../../../assets/images/index/screenshots/screenie-3.jpg";
import grandMotherAndGrandson from "../../../assets/images/index/grandmother-and-grandson.jpg";
import quoteZoneLogo from "../../../assets/images/third-party-logos/quote-zone-logo.png";
import giftCard1 from "../../../assets/images/gift-cards/life-style-gift-card.jpg";
import giftCard2 from "../../../assets/images/gift-cards/john-lewis-gift-card.jpg";
import giftCard3 from "../../../assets/images/gift-cards/river-island-gift-card.jpg";
import giftCard4 from "../../../assets/images/gift-cards/one4all-gift-card.jpg";
import giftCard5 from "../../../assets/images/gift-cards/boots-gift-card.jpg";
import giftCard6 from "../../../assets/images/gift-cards/m-and-s-gift-card.jpg";
import TestimonialsSlider from "../../TestimonialsSlider/TestimonialsSlider";

const Home = () => {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  // hello
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="hero pb-0">
            <Container>
              <Row className="justify-content-center">
                <Col xl="17">
                  <h1 className="text-center mb-4 mb-md-5">
                    Making life simple for the elderly and their families
                  </h1>
                </Col>
                <Col xl="14">
                  <p className="lead text-center mb-6 mb-md-7">
                    Manage your outgoings, save on your bills, set reminders,
                    get discounts, find exclusive offers, so you can focus on
                    living.
                  </p>
                </Col>
              </Row>
              <div className="text-center">
                <Button href="/plans" size="lg">
                  Sign up free
                </Button>
              </div>

              <div
                className="my-10 d-flex align-items-center justify-content-center fs-48 fw-bold"
                style={{ color: "#36f1cc" }}
              >
                Track. Save. Support – All in One Place.
              </div>
            </Container>
          </div>
          <Container>
            <section className="pb-9 pb-md-10 pb-xl-11 pb-xxxl-12">
              <Row className="justify-content-center gy-5 gx-xxxl-6">
                <Col lg xl="8" xxl className="d-none d-lg-flex">
                  <Row className="gy-4">
                    <Col lg="24">
                      <Image
                        src={HeroGraphicReminders}
                        className="img-fluid"
                        alt=""
                      />
                    </Col>
                    <Col lg="24">
                      <Image
                        src={sonCookingWithElderlyFather}
                        className="img-object-fit-cover rounded-4"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="auto" xl xxl="auto" xxxl="11" className="d-flex">
                  <Image
                    src={HeroGraphicOutgoings}
                    className="img-fluid d-lg-none d-xl-block d-xxl-none d-xxxl-block"
                    alt=""
                  />
                  <Image
                    src={HeroGraphicOutgoings}
                    width="600"
                    className="img-fluid d-none d-lg-block d-xl-none d-xxl-block d-xxxl-none"
                    alt=""
                  />
                </Col>
                <Col lg="24" xxl className="d-xxxl-flex">
                  <Row className="gy-4">
                    <Col md xxl="24">
                      <Image
                        src={grandadGradeningWithGranddaughter}
                        className="img-object-fit-cover rounded-4 d-lg-none d-xxl-block"
                        alt=""
                      />
                      <div
                        className="d-none d-lg-block d-xl-none"
                        style={{ height: "239px" }}
                      >
                        <Image
                          src={grandadGradeningWithGranddaughter}
                          className="img-object-fit-cover rounded-4"
                          alt=""
                        />
                      </div>
                      <div
                        className="d-none d-xl-block d-xxl-none"
                        style={{ height: "281px" }}
                      >
                        <Image
                          src={grandadGradeningWithGranddaughter}
                          className="img-object-fit-cover rounded-4"
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col md lg="auto" xxl="24" className="d-none d-md-block">
                      <Image
                        src={HeroGraphicBenefits}
                        className="img-fluid d-lg-none d-xxl-block"
                        alt=""
                      />
                      <Image
                        src={HeroGraphicBenefits}
                        width="300"
                        className="img-fluid d-none d-lg-block d-xl-none"
                        alt=""
                      />
                      <Image
                        src={HeroGraphicBenefits}
                        width="353"
                        className="img-fluid d-none d-xl-block d-xxl-none"
                        alt=""
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
            <section className="pt-8 pb-11 pt-md-9 pb-md-12 pt-xl-10 pb-xl-13 pt-xxxl-11 pb-xxxl-14">
              <Row className="justify-content-center mb-10 mb-md-11 mb-xl-12">
                <Col xl="20" xxxl="17">
                  <h2 className="text-center mb-5 mb-md-6">
                    It’s about time somebody used that fancy tech stuff to
                    reduce headaches
                  </h2>
                  <p className="lead text-center mb-0">
                    Elderly Life harmonises the boring things in life so you can
                    focus on the important things.
                  </p>
                </Col>
              </Row>
              <Row className="row-cols-1 row-cols-md-2 row-cols-xl-4 justify-content-center gy-5 gx-xxxl-6">
                <Col>
                  <div className="bg-primary-light rounded-3 p-6 p-xxxl-7 h-100">
                    <Row className="justify-content-center mb-7">
                      <Col xs="18" md="19" xl xxl="21" xxxl="18">
                        <h6 className="text-center mb-3">
                          Track every penny, from bills to Netflix
                        </h6>
                        <p className="small text-dark text-center mb-0">
                          Connect all your outgoings for hundreds of brands.
                        </p>
                      </Col>
                    </Row>
                    <Image
                      src={screenie1}
                      className="img-fluid rounded-3 shadow"
                      alt=""
                    />
                  </div>
                </Col>
                <Col>
                  <div className="bg-primary-light rounded-3 p-6 p-xxxl-7 h-100">
                    <Row className="justify-content-center mb-7">
                      <Col xs="18" xl xxl="21" xxxl="18">
                        <h6 className="text-center mb-3">
                          Save money with Compare & Save
                        </h6>
                        <p className="small text-dark text-center mb-0">
                          Find better deals with our price comparison tool.
                        </p>
                      </Col>
                    </Row>
                    <Image
                      src={screenie2}
                      className="img-fluid rounded-3 shadow mb-3"
                      alt=""
                    />
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="small-xs fw-semibold text-center">
                        Powered by
                      </div>
                      <Image
                        src={quoteZoneLogo}
                        height="16"
                        className="ms-2"
                        alt=""
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="bg-primary-light rounded-3 p-6 p-xxxl-7 h-100">
                    <Row className="justify-content-center mb-7">
                      <Col xs="19" md="20" xl xxl="22" xxxl="19">
                        <h6 className="text-center mb-3">
                          Never forget another birthday present
                        </h6>
                        <p className="small text-dark text-center mb-0">
                          Set reminders for the theatre tickets, birthday’s and
                          more.
                        </p>
                      </Col>
                    </Row>
                    <div className="me-9">
                      <Image
                        src={screenie3}
                        className="img-fluid rounded-3 shadow"
                        alt=""
                      />
                    </div>
                    <div className="ms-9 mt-4">
                      <Image
                        src={grandMotherAndGrandson}
                        className="img-fluid rounded-3 shadow"
                        alt=""
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="bg-primary-light rounded-3 p-6 p-xxxl-7 h-100">
                    <Row className="justify-content-center mb-7">
                      <Col xs="19" md="20" xl xxl="23" xxxl="19">
                        <h6 className="text-center mb-3">
                          Keep track of gift cards and warranties
                        </h6>
                        <p className="small text-dark text-center mb-0">
                          Never let a gift card, voucher or warranty expire
                          again.
                        </p>
                      </Col>
                    </Row>
                    <Row className="justify-content-center gy-5 gx-xxxl-6 gy-xxxl-6">
                      <Col xs="auto">
                        <Image
                          src={giftCard1}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard2}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard3}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard4}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard5}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard6}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </section>
            <SupportUserSection />
            <UspSection />
          </Container>
          <TestimonialsSlider />
          <PricingPlanCallToAction />
          <PageCallToAction messageContent="Make life more simple today." />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
