import React from "react";
import NavBar from "../templates/NavBar";

const Header = () => {
  return (
    <NavBar />
  );
};

export default Header;
