import React from "react";
import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logoheader from "../header/Logoheader";
import C50 from "../assets/Component 50 – 1.svg";
import C51 from "../assets/Component 51 – 1.svg";
import C54 from "../assets/Component 54 – 1.svg";
import C57 from "../assets/Component 57 – 1.svg";

import call from "../assets/telephone-call.svg";
import location from "../assets/Location.svg";
import paperplane from "../assets/paper-plane (1).svg";

const PageNotFound = () => {
  return (
    <>
      <Logoheader />
      <section className="no-page text-center">
        <Container>
          <h1 className="ft-bold">404</h1>
          <h4 className="mb-3">Oops! Page Not Found.</h4>
          <p>The page you’ve requested could not be found.</p>
          <Link className="btn btn-info custom-btn mt-4" to="/">
            Back to home page
          </Link>
          <div className="img-404">
            <Nav className="social-icons">
              <Nav.Link>
                <img alt="elderly_life facebook" src={C50} />
              </Nav.Link>
              <Nav.Link>
                <img alt="elderly_life linkedin" src={C51} />
              </Nav.Link>
              <Nav.Link>
                <img alt="elderly_life twitter" src={C54} />
              </Nav.Link>
              <Nav.Link>
                <img alt="elderly_life whatsapp" src={C57} />
              </Nav.Link>
            </Nav>
          </div>

          <div className="img-404-1">
            <Nav className="social-icons">
              <Nav.Link>
                <img alt="elderly_life location" src={location} />
              </Nav.Link>
              <span className="img-404-txt">
                71 Main St, ACASTER SELBY, YO5 6ZT
              </span>
              <Nav.Link>
                <img alt="elderly_life paperplane" src={paperplane} />
              </Nav.Link>
              <span className="img-404-txt">contact@elderlylife.com</span>
              <Nav.Link>
                <img alt="elderly_life telephone" src={call} />
              </Nav.Link>
              <span className="img-404-txt">078 2821 1593</span>
            </Nav>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PageNotFound;
